import { mapActions } from "vuex";

export default {
  data() {
    return {
      country_data: [],
      selected_country: {
        id: 1,
        logo: "Nigeria",
        name: "Nigeria",
        dialing_code: "+234",
      },

      password_type: true,
      show_error: false,
      show_failed_login: false,
      failed_login_msg: "",

      option_select: false,
    };
  },

  mounted() {
    this.getAllCountries();
  },

  methods: {
    ...mapActions({
      getCountries: "general/getCountries",
    }),

    getAllCountries() {
      this.getCountries()
        .then((response) => {
          if (response.status_code === 200) {
            // console.log(response.data);
            this.country_data = response.data;
            this.prepopulateCountry();
          } else this.country_data = [];
        })
        .catch(() => (this.country_data = []));
    },

    prepopulateCountry() {
      let current_country = this.country_data.filter(
        (country) => country.name == "Nigeria"
      );

      this.selected_country.id = current_country[0].id;
      this.selected_country.name = current_country[0].name;
      this.selected_country.logo = current_country[0].logo;
      this.selected_country.dialing_code = current_country[0].dialing_code;

      this.form.country_id = current_country[0].id.toString();
    },

    updateCountryState(index) {
      this.selected_country.id = this.country_data[index].id;
      this.selected_country.name = this.country_data[index].name;
      this.selected_country.logo = this.country_data[index].logo;
      this.selected_country.dialing_code = this.country_data[
        index
      ].dialing_code;

      this.form.country_id = this.country_data[index].id.toString();
    },

    // FORMAT PHONE TO 11 CHARACTERS
    formatPhone(phone, dialing_code) {
      if (phone.charAt(0) == 0)
        return `${dialing_code.substring(1)}${phone.substring(1)}`;
      else if (phone.charAt(0) === "+") return phone.substring(1);
      else if (phone.length === 10)
        return `${dialing_code.substring(1)}${phone}`;
      else return phone;
    },

    togglePasswordType() {
      this.password_type = !this.password_type;
    },

    watchPasswordField() {
      this.show_error = this.form.password.length < 6 ? true : false;
    },

    toggleView() {
      this.option_select = !this.option_select;
    },

    forceClose() {
      this.option_select = false;
    },
  },
};
