<template>
  <modal-cover
    @closeModal="$emit('closeTriggered')"
    modal_style="modal-xs"
    :show_close="true"
  >
    <!-- MODAL BODY -->
    <template slot="modal-cover-body">
      <div class="modal-body h-auto mgt-36">
        <div class="icon mgb-20">
          <div class="avatar rounded-circle">
            <img
              v-lazy="staticAsset('Success-icon.svg', 'subscriptions')"
              alt=""
            />
          </div>
        </div>

        <!-- TITLE -->
        <div
          class="modal-title brand-dark-blue font-weight-700 mgb-12 text-center text-capitalize"
        >
          Password reset complete!
        </div>

        <!-- META TEXT -->
        <div class="meta-text dark-text text-center mgb-25">
          Click on ‘Got it’ to redirect login
        </div>

        <div class="button-row d-flex justify-content-center w-100">
          <router-link to="/login" class="btn btn-md btn-primary pdx-40 mgl-2">
            Got it
          </router-link>
        </div>
      </div>
    </template>
  </modal-cover>
</template>

<script>
import modalCover from "@/shared/components/modal-cover";
import successIcon from "@/shared/components/success-icon";

export default {
  name: "SuccessModal",

  components: {
    modalCover,
    successIcon,
  },

  props: {
    custom_msg: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.modal-body {
  @include flex-column-center;
  position: relative;
  background: $brand-white;
  padding: 32px 24px;

  .icon {
    .avatar {
      background: #b8ffdb;
      @include square-shape(53);

      img {
        @include center-placement;
        @include square-shape(33);
      }
    }
  }

  .modal-title {
    @include font-height(18, 20);
    letter-spacing: 0.02em;
  }

  .meta-text {
    @include font-height(13, 22);
    letter-spacing: 0.02em;
  }
}
</style>
