<template>
  <div class="auth-page">
    <div class="container">
      <div class="row mx-auto">
        <div class="content col-12 col-xl-5 px-xl-0 mx-auto">
          <!-- FORM CARD  -->
          <form action="" @submit.prevent="registerUser">
            <form-card :form_data="page_data">
              <template slot="body-content">
                <div>
                  <div
                    class="d-flex justify-content-between align-items-start mgb-24"
                  >
                    <!-- FIRST NAME  -->
                    <div style="width: 48%">
                      <label for="firstName" class="label-text"
                        >Firstname</label
                      >
                      <input
                        type="text"
                        v-model="form.first_name"
                        class="form-control"
                        id="firstName"
                        placeholder="First Name"
                      />
                    </div>

                    <!-- LAST NAME  -->
                    <div style="width: 48%">
                      <label for="lastName" class="label-text">Lastname</label>
                      <input
                        type="text"
                        v-model="form.last_name"
                        class="form-control"
                        id="lastName"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>

                  <!-- PHONE NUMBER  -->
                  <div class="mgb-24">
                    <label class="label-text">Phone number</label>
                    <div class="input-container">
                      <!-- DROPDOWN SELECTOR -->
                      <div
                        class="select-wrapper dropdown-selector"
                        @click="toggleView"
                        v-on-clickaway="forceClose"
                      >
                        <!-- SELECTOR VALUE -->
                        <div class="dropdown-selector-value">
                          <!-- LOGO -->
                          <div class="avatar logo">
                            <img
                              v-lazy="
                                selected_country.logo.startsWith('https')
                                  ? selected_country.logo
                                  : dynamicAsset('Nigeria.svg', 'subscriptions')
                              "
                              alt=""
                              class="avatar-img"
                            />
                          </div>

                          <!-- VALUE -->
                          <div class="value">
                            {{ selected_country.dialing_code }}
                          </div>
                        </div>

                        <!-- SELECTOR ICON -->
                        <div
                          class="dropdown-icon icon-caret-down brand-black smooth-transition"
                          :class="{ 'rotate-180': option_select }"
                        ></div>

                        <!-- OPTION WRAPPER -->
                        <div
                          class="select-option-wrapper rounded-4 index-999 mgt-5 smooth-animation smooth-animation"
                          v-if="option_select"
                        >
                          <!-- OPTIONS -->
                          <div
                            class="option-scroll-wrapper index-999"
                            style="z-index: 9999"
                          >
                            <div
                              class="option-row option-mix-row text-capitalize"
                              v-for="(country, index) in country_data"
                              @click="updateCountryState(index)"
                              :key="index"
                            >
                              <div class="left">
                                {{ country.name }} ({{ country.dialing_code }})
                              </div>

                              <div class="right">
                                <div class="avatar">
                                  <img
                                    v-lazy="country.logo"
                                    alt=""
                                    class="avatar-img"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <input
                        type="number"
                        class="form-control"
                        v-model="form.phone"
                        placeholder="e.g 090656873xx"
                      />
                    </div>
                  </div>

                  <!-- EMAIL ADDRESS -->
                  <div class="mgb-24">
                    <label for="email" class="label-text">Email address</label>
                    <input
                      type="text"
                      v-model="form.email"
                      class="form-control"
                      id="email"
                      placeholder="Email address"
                    />
                  </div>

                  <!-- DOB -->
                  <div class="mgb-24">
                    <label for="DOB" class="label-text">Date of Birth</label>
                    <input
                      type="date"
                      v-model="form.dob"
                      class="form-control"
                      id="DOB"
                    />
                  </div>

                  <!-- GENDER  -->
                  <div class="mgb-24">
                    <label class="label-text">Gender</label>
                    <single-data-select
                      placeholder="Select gender"
                      :options="gender_data"
                      :pre_select="pre_gender"
                      :clear_selection="clear_selection"
                      @selectedOption="form.gender = $event.toString()"
                    />
                  </div>

                  <!-- PASSWORD  -->
                  <div
                    class="password-block mgb-24"
                    :class="show_error ? 'error-input' : null"
                  >
                    <label for="password" class="label-text">Password</label>
                    <div class="position-relative">
                      <input
                        :type="password_type ? 'password' : 'text'"
                        v-model="form.password"
                        class="form-control"
                        id="password"
                        placeholder="Password"
                        @input="watchPasswordField"
                      />

                      <div
                        class="show-password-btn position-absolute light-dark-text smooth-transition pointer"
                        @click="togglePasswordType"
                      >
                        {{ password_type ? "Show" : "Hide" }}
                      </div>
                    </div>

                    <!-- ERROR INPUT TEXT -->
                    <div
                      class="error-input-msg mgt-5 font-weight-600 brand-red"
                      v-if="show_error"
                    >
                      Password must be a minimum of 6 characters
                    </div>
                  </div>

                  <!-- SUBMIT BUTTON -->
                  <button
                    type="submit"
                    class="btn btn-block btn-primary mgt-16 w-100"
                    ref="createAccountBtn"
                    :disabled="isDisabled"
                  >
                    Continue
                  </button>
                </div>
              </template>
            </form-card>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import formCard from "@/shared/components/form-card";
import singleDataSelect from "@/shared/components/single-data-select";
import authMixinHelper from "@/shared/mixins/authMixinHelper";

export default {
  name: "createAccount",

  metaInfo: {
    title: "Create Account",
  },

  mixins: [authMixinHelper],

  components: {
    formCard,
    singleDataSelect,
  },

  computed: {
    isDisabled() {
      return this.form.first_name &&
        this.form.last_name &&
        this.form.password.length > 5 &&
        this.form.phone &&
        this.form.gender &&
        this.form.dob
        ? false
        : true;
    },
  },

  data: () => ({
    page_data: {
      show_back_link: true,
      back_link_route: "",
      header: "Create account",
      description:
        "We noticed you do not have an account with us, please create one by adding a password below.",
      footer_text: "",
      footer_link: "",
      bottom_text: "Already have an account?",
      bottom_link_text: "Login here",
      bottom_link: "/login",
    },

    form: {
      first_name: "",
      last_name: "",
      email: "",
      gender: "",
      dob: "",
      password: "",
      phone: "",
      country_id: "",
    },

    gender_data: [
      { id: "male", name: "Male" },
      { id: "female", name: "Female" },
    ],
    pre_gender: null,
    clear_selection: false,
  }),

  methods: {
    ...mapActions({
      registerNewUser: "auth/registerNewUser",
    }),

    registerUser() {
      this.$refs.createAccountBtn.disabled = true;
      this.$refs.createAccountBtn.innerHTML =
        "<div class='btn-spin animate icon-spinner'></div>";

      // FORMAT PHONE
      let formatted_phone = this.formatAccountPhone(this.form.phone);

      delete this.form.phone;
      let payload = { ...this.form, phone: formatted_phone };

      this.registerNewUser(payload)
        .then((response) => {
          if (response?.status_code === 200) {
            setTimeout(() => {
              this.$refs.createAccountBtn.disabled = true;
              this.$refs.createAccountBtn.innerHTML =
                "Registration Successful!";
            }, 1000);

            // CHECK IF SUBSCRIPTION
            setTimeout(() => {
              if (this.$route.query.type === "subscription") {
                location.href = "/select-subscription?verify=true";
              } else {
                location.href = "/select-subscription?plan=basic";
              }
            }, 3000);
          } else {
            this.$refs.createAccountBtn.disabled = false;
            this.$refs.createAccountBtn.innerHTML = "Continue";
          }
        })
        .catch(() => {
          this.$refs.createAccountBtn.disabled = false;
          this.$refs.createAccountBtn.innerHTML = "Continue";
        });
    },

    formatAccountPhone(phone) {
      if (phone.charAt(0) == 0) return `${phone.substring(1)}`;
      else return phone;
    },

    logOut() {
      this.$bus.$emit("show-page-loader", "Logging you out");
    },
  },
};
</script>

<style lang="scss" scoped></style>
