<template>
  <div class="auth-page">
    <div class="container">
      <div class="row mx-auto">
        <div class="content col-12 col-xl-5 px-xl-3 mx-auto">
          <!-- FORM CARD  -->
          <form-card :form_data="page_data">
            <template slot="body-content">
              <div>
                <div class="input-data-row">
                  <input
                    type="text"
                    v-model="serial_one"
                    ref="serialOne"
                    :maxlength="1"
                    class="form-control"
                  />
                  <input
                    type="text"
                    v-model="serial_two"
                    ref="serialTwo"
                    class="form-control"
                    :maxlength="1"
                    :disabled="checkInputOne"
                  />
                  <input
                    type="text"
                    v-model="serial_three"
                    ref="serialThree"
                    class="form-control"
                    :maxlength="1"
                    :disabled="checkInputTwo"
                  />
                  <input
                    type="text"
                    v-model="serial_four"
                    ref="serialFour"
                    class="form-control"
                    :maxlength="1"
                    :disabled="checkInputThree"
                  />
                  <input
                    type="text"
                    v-model="serial_five"
                    ref="serialFive"
                    class="form-control"
                    :maxlength="1"
                    :disabled="checkInputFour"
                  />
                  <input
                    type="text"
                    v-model="serial_six"
                    ref="serialSix"
                    class="form-control"
                    :maxlength="1"
                    :disabled="checkInputFive"
                  />
                </div>

                <!-- SUBMIT BUTTON -->
                <button
                  type="submit"
                  class="btn btn-block btn-primary mgt-16 w-100"
                  ref="verifyBtn"
                  :disabled="isDisabled"
                  @click="verifyPhoneNumber"
                >
                  Continue
                </button>

                <!-- RESEND OTP -->
                <div class="w-100 d-flex justify-content-center">
                  <div
                    class="help-text pointer text-center"
                    @click="resendCode"
                    ref="OTPBtn"
                  >
                    Resend Code
                  </div>
                </div>
              </div>
            </template>
          </form-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import formCard from "@/shared/components/form-card";

export default {
  name: "verifyPhone",

  metaInfo: {
    title: "Verify Phone",
  },

  components: {
    formCard,
  },

  computed: {
    isDisabled() {
      return this.serial_one.length === 1 &&
        this.serial_two.length === 1 &&
        this.serial_three.length === 1 &&
        this.serial_four.length === 1 &&
        this.serial_five.length === 1 &&
        this.serial_six.length === 1
        ? false
        : true;
    },

    checkInputOne() {
      return this.serial_one.length === 1 ? false : true;
    },

    checkInputTwo() {
      return this.serial_two.length === 1 ? false : true;
    },

    checkInputThree() {
      return this.serial_three.length === 1 ? false : true;
    },

    checkInputFour() {
      return this.serial_four.length === 1 ? false : true;
    },

    checkInputFive() {
      return this.serial_five.length === 1 ? false : true;
    },

    checkInputSix() {
      return this.serial_six.length === 1 ? false : true;
    },
  },

  data: () => ({
    page_data: {
      show_back_link: true,
      back_link_route: "",
      header: "Verify your phone number",
      description:
        "A 6-digit code has been sent to your registered phone number, please enter it below.",
      footer_text: "",
      footer_link: "",
    },

    serial_one: "",
    serial_two: "",
    serial_three: "",
    serial_four: "",
    serial_five: "",
    serial_six: "",
  }),

  watch: {
    serial_one: {
      handler(value) {
        if (value.length === 1)
          this.$nextTick(() => this.$refs.serialTwo.focus());
      },
    },

    serial_two: {
      handler(value) {
        if (value.length === 1)
          this.$nextTick(() => this.$refs.serialThree.focus());
      },
    },

    serial_three: {
      handler(value) {
        if (value.length === 1)
          this.$nextTick(() => this.$refs.serialFour.focus());
      },
    },

    serial_four: {
      handler(value) {
        if (value.length === 1)
          this.$nextTick(() => this.$refs.serialFive.focus());
      },
    },

    serial_five: {
      handler(value) {
        if (value.length === 1)
          this.$nextTick(() => this.$refs.serialSix.focus());
      },
    },
  },

  methods: {
    ...mapActions({
      resendPhoneOTP: "auth/resendPhoneOTP",
      verifyPhoneOTP: "auth/verifyPhoneOTP",
    }),

    verifyPhoneNumber() {
      this.$refs.verifyBtn.disabled = true;
      this.$refs.verifyBtn.innerHTML =
        "<div class='btn-spin animate icon-spinner'></div>";

      let phone_otp = `${this.serial_one}${this.serial_two}${this.serial_three}${this.serial_four}${this.serial_five}${this.serial_six}`;

      let payload = {
        phone: this.$route.params.phone,
        pin_id: localStorage.getItem("phone_pin"),
        otp: phone_otp,
      };

      this.verifyPhoneOTP(payload)
        .then((response) => {
          if (response?.data?.message === "OTP is verified") {
            this.$refs.verifyBtn.disabled = false;
            this.$refs.verifyBtn.innerHTML = "Verified";

            setTimeout(() => {
              this.$router.push({
                name: "Class54CreatePassword",
                params: { phone: this.$route.params.phone },
                query: { verified: phone_otp },
              });
            }, 2000);
          } else {
            this.$refs.verifyBtn.disabled = false;
            this.$refs.verifyBtn.innerHTML = "Not Verified";

            setTimeout(() => {
              this.$router.push({
                name: "Class54ForgotPassword",
              });
            }, 2000);
          }
        })
        .catch(() => {
          this.$refs.verifyBtn.disabled = false;
          this.$refs.verifyBtn.innerHTML = "Not Verified";

          setTimeout(() => {
            this.$router.push({
              name: "Class54ForgotPassword",
            });
          }, 2000);
        });
    },

    resendCode() {
      this.$refs.OTPBtn.innerHTML = "Resending OTP...";

      this.resendPhoneOTP(this.$route.params.phone)
        .then((response) => {
          if (response.hasOwnProperty("data")) {
            localStorage.setItem("phone_pin", response.data.pin_id);

            setTimeout(
              () => (this.$refs.OTPBtn.innerHTML = "OTP Resent!"),
              500
            );

            setTimeout(
              () => (this.$refs.OTPBtn.innerHTML = "Resend Code"),
              4000
            );
          }
        })
        .catch(() => {
          this.$refs.OTPBtn.innerHTML = "Resend Code";
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.input-data-row {
  @include flex-row-center-nowrap;
  margin-bottom: toRem(25);

  .form-control {
    width: 15.5%;
    margin: 0 toRem(3.6);
    font-size: toRem(13.5);
    font-weight: 600;
    text-align: center;
  }
}
</style>
