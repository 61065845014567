<template>
  <div class="base-landing-layout">
    <!-- NAV COMPONENTS  -->
    <nav-build isBasePage />

    <transition name mode="out-in">
      <div class="content-block">
        <router-view />
      </div>
    </transition>

    <!-- FOOTER BUILD  -->
    <template>
      <footer-build />
    </template>
  </div>
</template>

<script>
import navBuild from "@/shared/components/nav-build";
import footerBuild from "@/shared/components/footer-build";

export default {
  name: "baseLandingLayout",

  components: {
    navBuild,
    footerBuild,
  },
};
</script>

<style lang="scss" scoped>
.content-block {
  margin-top: toRem(60);
}
</style>
