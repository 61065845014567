<template>
  <div class="auth-page">
    <div class="container">
      <div class="row mx-auto">
        <div class="content col-12 col-xl-5 px-xl-1 mx-auto">
          <!-- FORM CARD  -->
          <form action="" @submit.prevent="handleUserLogin">
            <form-card :form_data="page_data">
              <template slot="body-content">
                <div>
                  <!-- PHONE NUMBER  -->
                  <div
                    class="email-block mgb-24"
                    :class="show_failed_login ? 'error-input' : null"
                  >
                    <label class="label-text">Phone number</label>
                    <div class="input-container">
                      <!-- DROPDOWN SELECTOR -->
                      <div
                        class="select-wrapper dropdown-selector"
                        @click="toggleView"
                        v-on-clickaway="forceClose"
                      >
                        <!-- SELECTOR VALUE -->
                        <div class="dropdown-selector-value">
                          <!-- LOGO -->
                          <div class="avatar logo">
                            <img
                              v-lazy="
                                selected_country.logo.startsWith('https')
                                  ? selected_country.logo
                                  : dynamicAsset('Nigeria.svg', 'subscriptions')
                              "
                              alt=""
                              class="avatar-img"
                            />
                          </div>

                          <!-- VALUE -->
                          <div class="value">
                            {{ selected_country.dialing_code }}
                          </div>
                        </div>

                        <!-- SELECTOR ICON -->
                        <div
                          class="dropdown-icon icon-caret-down brand-black smooth-transition"
                          :class="{ 'rotate-180': option_select }"
                        ></div>

                        <!-- OPTION WRAPPER -->
                        <div
                          class="select-option-wrapper rounded-4 index-999 mgt-5 smooth-animation smooth-animation"
                          v-if="option_select"
                        >
                          <!-- OPTIONS -->
                          <div
                            class="option-scroll-wrapper index-999"
                            style="z-index: 9999"
                          >
                            <div
                              class="option-row option-mix-row text-capitalize"
                              v-for="(country, index) in country_data"
                              @click="updateCountryState(index)"
                              :key="index"
                            >
                              <div class="left">
                                {{ country.name }} ({{ country.dialing_code }})
                              </div>

                              <div class="right">
                                <div class="avatar">
                                  <img
                                    v-lazy="country.logo"
                                    alt=""
                                    class="avatar-img"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <input
                        type="number"
                        class="form-control"
                        v-model="form.phone"
                        placeholder="e.g 090656873xx"
                      />
                    </div>
                    <!-- ERROR INPUT TEXT -->
                    <div
                      class="error-input-msg mgt-5 font-weight-600 brand-red"
                      style="text-align: right"
                      v-if="show_failed_login"
                    >
                      {{ failed_login_msg }}
                    </div>
                  </div>

                  <!-- PASSWORD BLOCK -->
                  <div
                    class="password-block mgb-24"
                    :class="show_error ? 'error-input' : null"
                  >
                    <!-- LABEL ROW -->
                    <div class="label-row d-flex justify-content-between mgb-2">
                      <label for="password" class="label-text">Password</label>
                      <router-link to="/forgot-password" class="help-text mt-0"
                        >Forgot Password?</router-link
                      >
                    </div>

                    <div class="position-relative">
                      <input
                        :type="password_type ? 'password' : 'text'"
                        v-model="form.password"
                        class="form-control"
                        id="password"
                        placeholder="Password"
                        @input="watchPasswordField"
                      />

                      <div
                        class="show-password-btn position-absolute light-dark-text smooth-transition pointer"
                        @click="togglePasswordType"
                      >
                        {{ password_type ? "Show" : "Hide" }}
                      </div>
                    </div>

                    <!-- ERROR INPUT TEXT -->
                    <div
                      class="error-input-msg mgt-5 font-weight-600 brand-red"
                      v-if="show_error"
                    >
                      Password must be a minimum of 6 characters
                    </div>
                  </div>

                  <!-- SUBMIT BUTTON -->
                  <button
                    type="submit"
                    class="btn btn-block btn-primary mgt-16 w-100"
                    ref="loginBtn"
                    :disabled="isDisabled"
                  >
                    Continue
                  </button>
                </div>
              </template>
            </form-card>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import formCard from "@/shared/components/form-card";
import authMixinHelper from "@/shared/mixins/authMixinHelper";

export default {
  name: "login",

  metaInfo: {
    title: "Login",
  },

  mixins: [authMixinHelper],

  components: {
    formCard,
  },

  computed: {
    isDisabled() {
      return [10, 11, 12, 13, 14].includes(this?.form?.phone?.length) &&
        this?.form?.password?.length > 5
        ? false
        : true;
    },
  },

  data: () => ({
    page_data: {
      show_back_link: true,
      back_link_route: "",
      header: "Log into your account",
      description: "Please enter your details to continue.",
      footer_text: "",
      footer_link: "",
      bottom_text: "Don’t have an account?",
      bottom_link_text: "Register here",
      bottom_link: "/create-account",
    },

    form: {
      phone: "",
      password: "",
    },
  }),

  methods: {
    ...mapActions({
      loginUser: "auth/loginUser",
      checkMobileSubscription: "storeSubscription/checkMobileSubscription",
    }),

    handleUserLogin() {
      this.$refs.loginBtn.disabled = true;
      this.$refs.loginBtn.innerHTML =
        "<div class='btn-spin animate icon-spinner'></div>";

      // FORMAT PHONE
      let formatted_phone = this.formatPhone(
        this.form.phone,
        this.selected_country.dialing_code
      );

      delete this.form.phone;
      delete this.form.country_id;

      let payload = { ...this.form, phone: formatted_phone };

      this.loginUser(payload)
        .then((response) => {
          if (response?.status_code === 200) {
            this.checkValidSubscription();
          } else {
            this.toggleFailedLogin("Invalid Login Credentials");
            this.form.password = "";
          }
        })
        .catch(() => {
          this.toggleFailedLogin("Invalid Login Credentials");
          this.form.password = "";
        });
    },

    checkValidSubscription() {
      this.checkMobileSubscription()
        .then((response) => {
          if (response?.status_code === 200) {
            if (response.data.is_active) {
              let { subscription_plan, valid_until } = response.data;
              let saved_payload = {
                plan_name: subscription_plan.name,
                plan_span: subscription_plan.validity,
                plan_validity: valid_until,
              };

              localStorage.setItem(
                "subscriptionInfo",
                JSON.stringify(saved_payload)
              );

              location.href = "/subscription-verified";
            } else location.href = "/select-subscription?plan=basic";
          } else location.href = "/select-subscription?plan=basic";
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // TOGGLE FAILED MESSAGE SETUP
    toggleFailedLogin(message) {
      this.show_failed_login = true;
      this.failed_login_msg = message;

      this.$refs.loginBtn.innerHTML = "Continue";
      this.$refs.loginBtn.disabled = false;

      setTimeout(() => {
        this.show_failed_login = false;
        this.failed_login_msg = "";
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped></style>
