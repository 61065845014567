<template>
  <div class="auth-page">
    <div class="container">
      <div class="row mx-auto">
        <div class="content col-12 col-xl-5 px-xl-3 mx-auto">
          <form action="" @submit.prevent="resetPassword">
            <!-- FORM CARD  -->
            <form-card :form_data="page_data">
              <template slot="body-content">
                <div>
                  <!-- PASSWORD BLOCK -->
                  <div
                    class="password-block mgb-24"
                    :class="show_error_one ? 'error-input' : null"
                  >
                    <label for="newPassword" class="label-text"
                      >New Password</label
                    >
                    <div class="position-relative">
                      <input
                        :type="password_type_one ? 'password' : 'text'"
                        v-model="form.new_password"
                        class="form-control"
                        id="newPassword"
                        placeholder="New Password"
                        @input="watchNewPasswordField"
                      />

                      <div
                        class="show-password-btn position-absolute light-dark-text smooth-transition pointer"
                        @click="togglePasswordTypeOne"
                      >
                        {{ password_type_one ? "Show" : "Hide" }}
                      </div>
                    </div>

                    <!-- ERROR INPUT TEXT -->
                    <div
                      class="error-input-msg mgt-5 font-weight-600 brand-red"
                      v-if="show_error_one"
                    >
                      {{ failed_msg_one }}
                    </div>
                  </div>

                  <!-- PASSWORD BLOCK -->
                  <div
                    class="password-block mgb-24"
                    :class="show_error_two ? 'error-input' : null"
                  >
                    <label for="confirmPassword" class="label-text"
                      >Confirm Password</label
                    >
                    <div class="position-relative">
                      <input
                        :type="password_type_two ? 'password' : 'text'"
                        v-model="form.confirm_password"
                        class="form-control"
                        id="confirmPassword"
                        placeholder="Confirm Password"
                        @input="watchConfirmPasswordField"
                      />

                      <div
                        class="show-password-btn position-absolute light-dark-text smooth-transition pointer"
                        @click="togglePasswordTypeTwo"
                      >
                        {{ password_type_two ? "Show" : "Hide" }}
                      </div>
                    </div>

                    <!-- ERROR INPUT TEXT -->
                    <div
                      class="error-input-msg mgt-5 font-weight-600 brand-red"
                      v-if="show_error_two"
                    >
                      {{ failed_msg_two }}
                    </div>
                  </div>

                  <!-- SUBMIT BUTTON -->
                  <button
                    type="submit"
                    class="btn btn-block btn-primary mgt-16 w-100"
                    ref="resetBtn"
                    :disabled="isDisabled"
                  >
                    Reset Password
                  </button>
                </div>
              </template>
            </form-card>
          </form>
        </div>
      </div>
    </div>

    <portal to="54-modals">
      <transition name="fade" v-if="show_success_modal">
        <password-reset-success-modal @closeTriggered="toggleSuccessModal" />
      </transition>
    </portal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import formCard from "@/shared/components/form-card";
import passwordResetSuccessModal from "@/shared/modals/password-reset-success-modal";

export default {
  name: "createPassword",

  metaInfo: {
    title: "Create Password",
  },

  components: {
    formCard,
    passwordResetSuccessModal,
  },

  computed: {
    isDisabled() {
      return this.form.new_password.length > 5 &&
        this.form.confirm_password.length > 5
        ? false
        : true;
    },
  },

  data: () => ({
    page_data: {
      show_back_link: false,
      back_link_route: "",
      header: "Create new password",
      description:
        "Please reset your password below by entering and confriming a new password",
      footer_text: "",
      footer_link: "",
    },

    form: {
      new_password: "",
      confirm_password: "",
    },

    password_type_one: true,
    password_type_two: true,
    show_success_modal: false,

    show_error_one: false,
    failed_msg_one: "",

    show_error_two: false,
    failed_msg_two: "",
  }),

  methods: {
    ...mapActions({ resetUserPassword: "auth/resetUserPassword" }),

    togglePasswordTypeOne() {
      this.password_type_one = !this.password_type_one;
    },

    togglePasswordTypeTwo() {
      this.password_type_two = !this.password_type_two;
    },

    toggleSuccessModal() {
      this.show_success_modal = !this.show_success_modal;
    },

    watchNewPasswordField() {
      if (this.form.new_password.length < 6) {
        this.show_error_one = true;
        this.failed_msg_one = "Password must be a minimum of 6 characters";
      } else this.show_error_one = false;
    },

    watchConfirmPasswordField() {
      if (this.form.confirm_password.length < 6) {
        this.show_error_two = true;
        this.failed_msg_two = "Password must be a minimum of 6 characters";
      } else if (this.form.new_password !== this.form.confirm_password) {
        this.show_error_two = true;
        this.failed_msg_two = "Password does not match!";
      } else this.show_error_two = false;
    },

    resetPassword() {
      this.$refs.resetBtn.disabled = true;
      this.$refs.resetBtn.innerHTML =
        "<div class='btn-spin animate icon-spinner'></div>";

      let payload = {
        phone: this.$route.params.phone,
        otp: this.$route.query.verified,
        password: this.form.new_password,
        password_confirmation: this.form.confirm_password,
        pin_id: localStorage.getItem("phone_pin"),
      };

      this.resetUserPassword(payload)
        .then((response) => {
          if (
            response?.data?.message === "You will now be redirected to login"
          ) {
            this.$refs.resetBtn.disabled = false;
            this.$refs.resetBtn.innerHTML = "Reset Complete!";

            setTimeout(() => this.toggleSuccessModal(), 2000);
          } else {
            this.$refs.resetBtn.disabled = false;
            this.$refs.resetBtn.innerHTML = "Reset Failed";

            this.$router.push("/forgot-password");
          }
        })
        .catch((response) => console.log(response));
    },
  },
};
</script>

<style lang="scss" scoped></style>
