<template>
  <div class="auth-page">
    <div class="container">
      <div class="row mx-auto">
        <div class="content col-12 col-xl-5 px-xl-3 mx-auto">
          <!-- FORM CARD  -->
          <form action="" @submit.prevent="handleForgetPassword">
            <form-card :form_data="page_data">
              <template slot="body-content">
                <div>
                  <!-- PHONE NUMBER  -->
                  <div
                    class="email-block mgb-24"
                    :class="show_failed_login ? 'error-input' : null"
                  >
                    <label class="label-text">Phone number</label>
                    <div class="input-container">
                      <!-- DROPDOWN SELECTOR -->
                      <div
                        class="select-wrapper dropdown-selector"
                        @click="toggleView"
                        v-on-clickaway="forceClose"
                      >
                        <!-- SELECTOR VALUE -->
                        <div class="dropdown-selector-value">
                          <!-- LOGO -->
                          <div class="avatar logo">
                            <img
                              v-lazy="
                                selected_country.logo.startsWith('https')
                                  ? selected_country.logo
                                  : dynamicAsset('Nigeria.svg', 'subscriptions')
                              "
                              alt=""
                              class="avatar-img"
                            />
                          </div>

                          <!-- VALUE -->
                          <div class="value">
                            {{ selected_country.dialing_code }}
                          </div>
                        </div>

                        <!-- SELECTOR ICON -->
                        <div
                          class="dropdown-icon icon-caret-down brand-black smooth-transition"
                          :class="{ 'rotate-180': option_select }"
                        ></div>

                        <!-- OPTION WRAPPER -->
                        <div
                          class="select-option-wrapper rounded-4 index-999 mgt-5 smooth-animation smooth-animation"
                          v-if="option_select"
                        >
                          <!-- OPTIONS -->
                          <div
                            class="option-scroll-wrapper index-999"
                            style="z-index: 9999"
                          >
                            <div
                              class="option-row option-mix-row text-capitalize"
                              v-for="(country, index) in country_data"
                              @click="updateCountryState(index)"
                              :key="index"
                            >
                              <div class="left">
                                {{ country.name }} ({{ country.dialing_code }})
                              </div>

                              <div class="right">
                                <div class="avatar">
                                  <img
                                    v-lazy="country.logo"
                                    alt=""
                                    class="avatar-img"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <input
                        type="number"
                        class="form-control"
                        v-model="form.phone"
                        placeholder="e.g 090656873xx"
                      />
                    </div>
                    <!-- ERROR INPUT TEXT -->
                    <div
                      class="error-input-msg mgt-5 font-weight-600 brand-red"
                      style="text-align: right"
                      v-if="show_failed_login"
                    >
                      {{ failed_login_msg }}
                    </div>
                  </div>

                  <!-- SUBMIT BUTTON -->
                  <button
                    type="submit"
                    class="btn btn-block btn-primary mgt-16 w-100"
                    ref="forgotPasswordBtn"
                    :disabled="isDisabled"
                  >
                    Continue
                  </button>
                </div>
              </template>
            </form-card>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import formCard from "@/shared/components/form-card";
import authMixinHelper from "@/shared/mixins/authMixinHelper";

export default {
  name: "forgotPassword",

  metaInfo: {
    title: "Forgot Password",
  },

  mixins: [authMixinHelper],

  components: {
    formCard,
  },

  computed: {
    isDisabled() {
      return [11, 12, 13, 14].includes(this.form.phone.length) ? false : true;
    },
  },

  data: () => ({
    page_data: {
      show_back_link: true,
      back_link_route: "",
      header: "Forgot password",
      description: "We need to be sure that you’re the one.",
      footer_text: "I remember my password now",
      footer_link: "/login",
    },

    form: {
      phone: "",
    },
  }),

  methods: {
    ...mapActions({ requestUserPassword: "auth/requestUserPassword" }),

    handleForgetPassword() {
      this.$refs.forgotPasswordBtn.disabled = true;
      this.$refs.forgotPasswordBtn.innerHTML =
        "<div class='btn-spin animate icon-spinner'></div>";

      let formatted_phone = this.formatPhone(
        this.form.phone,
        this.selected_country.dialing_code
      );

      delete this.form.phone;
      delete this.form.country_id;
      let payload = { ...this.form, phone: formatted_phone };

      this.requestUserPassword(payload)
        .then((response) => {
          if (response.hasOwnProperty("data")) {
            this.$refs.forgotPasswordBtn.disabled = false;
            this.$refs.forgotPasswordBtn.innerHTML = "OTP Sent!";
            localStorage.setItem("phone_pin", response.data.pin_id);

            setTimeout(() => {
              this.$router.push({
                name: "Class54VerifyPhone",
                params: { phone: formatted_phone },
              });
            }, 2000);
          } else this.toggleFailedAttempt("Phone number does not exist");
        })
        .catch(() => this.toggleFailedAttempt("Phone number does not exist"));
    },

    toggleFailedAttempt(message) {
      this.show_failed_login = true;
      this.failed_login_msg = message;

      this.$refs.forgotPasswordBtn.innerHTML = "Continue";
      this.$refs.forgotPasswordBtn.disabled = false;

      setTimeout(() => {
        this.show_failed_login = false;
        this.failed_login_msg = "";
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped></style>
